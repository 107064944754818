.mail-previewer-container blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
}


.mail-previewer-container .text-tiny {
    font-size: .7em;
}

.mail-previewer-container .text-small {
    font-size: .85em;
}

.mail-previewer-container .text-big {
    font-size: 1.4em;
}

.mail-previewer-container .text-huge {
    font-size: 1.8em;
}