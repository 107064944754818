.react-tags {
  position: relative;
  padding: .25rem 0 0 .25rem;
  border: 2px solid #afb8c1;
  border-radius: 6px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text
}

.react-tags.is-active {
  border-color: #4f46e5
}

.react-tags.is-disabled {
  opacity: .75;
  background-color: #eaeef2;
  pointer-events: none;
  cursor: not-allowed
}

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px #fd565340
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden
}

.react-tags__list {
  display: inline;
  padding: 0
}

.react-tags__list-item {
  display: inline;
  list-style: none
}

.react-tags__tag {
  box-sizing: border-box;
  margin: 0 .25rem .25rem 0;
  padding: .375rem .5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  font-size: inherit;
  line-height: inherit
}

.react-tags__tag:hover {
  color: #fff;
  background-color: #4f46e5
}

.react-tags__tag:after {
  content: "\2715";
  margin-left: .5rem;
  font-size: .875rem
}

.react-tags__tag:not(:hover):after {
  color: #7c7d86
}

.react-tags__combobox {
  display: inline-block;
  padding: .375rem .25rem;
  margin-bottom: .25rem;
  max-width: 100%
}

.react-tags__combobox-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  font-size: inherit;
  line-height: inherit
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1
}

.react-tags__listbox {
  position: absolute;
  z-index: 9999;
  top: calc(100% + 5px);
  left: -2px;
  right: -2px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow: #0000001a 0 10px 15px -4px, #0000000d 0 4px 6px -2px
}

.react-tags__listbox-option {
  padding: .375rem .5rem
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eaeef2
}

.react-tags__listbox-option:not([aria-disabled="true"]).is-active {
  background: #4f46e5;
  color: #fff
}

.react-tags__listbox-option[aria-disabled=true] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none
}

.react-tags__listbox-option[aria-selected=true]:after {
  content: "\2713";
  margin-left: .5rem
}

.react-tags__listbox-option[aria-selected=true]:not(.is-active):after {
  color: #4f46e5
}